<template>
  <div class="wrapper">
    <div class="relative">
      <div class="loading" v-show="isLoading">
        <img :src="require('/src/assets/images/loading.png')" alt="">
      </div>
      <div class="row" style="text-align: left">
        <div class="col-md-12 mb-3">
          <div class="progress-documents mt-4 d-flex justify-content-between">
            For faster results paste your pay data
            <a class="tutorial" data-bs-toggle="modal" href="#tutorial" role="button">Tutorial</a>
          </div>

          <div v-if="step === 1 || step ===2">
            <div class="row mt-5">
              <div class="offset-md-2 col-md-8">
                <div class="grey-header">
                  <div class="row">
                    <div class="col-md-4" @click="printPayroll">Name</div>
                    <div class="col-md-4">Check Date</div>
                    <div class="col-md-4">Gross</div>
                  </div>
                </div>
                <div class="table-body" @paste="onPaste($event, step)">
                  <div class="table-row" v-for="(item, index) in getSource(1)" :key="index">
                    <div class="row">
                      <div class="col-md-4">
                        <input
                            @keyup="handleKeyEvent"
                            @change="updateJSON($event, 2)"
                            :placeholder="source.length === 1 ? 'Paste data here...' : ''"
                            :value="item.name"
                            :data-index="index"
                            data-key="name"
                            type="text"
                            class="form-control td-names"/>
                      </div>
                      <div class="col-md-4" :class="item.outOfRange ? 'out-of-range' : ''">
                        <input
                            @keyup="handleKeyEvent"
                            @change="updateJSON($event, 2)"
                            placeholder="mm/dd/yyyy"
                            :data-index="index"
                            :value="item.date"
                            data-key="date"
                            type="text"
                            :title="item.outOfRange ? 'This date is out of eligible range' : ''"
                            class="form-control td-dates"/>
                      </div>
                      <div class="col-md-4">
                        <input
                            @keyup="handleKeyEvent"
                            @change="updateJSON($event, 2)"
                            :data-index="index"
                            :value="formatNumber(item.amount)"
                            data-key="amount"
                            type="text"
                            class="form-control td-wages"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="text-center mb-5">
                <button @click="step = 2, actions(2)" class="submit me-3">
                  Save
                </button>
                <button @click="clearTable(1)" class="submit">
                  Clear
                </button>
              </div>
            </div>
          </div>

          <!--step-2-->
          <div v-if="step === 2" class="table-wrapper mt-4">
            <div class="thick-divider mb-4"></div>
            <div class="section-title mb-4">Total Entries</div>
            <div class="row mb-4">
              <div class="col-12 col-md-4 offset-md-2">
                <div class="container">
                  <div class="row quarters">
                    <span class="year">2020</span>
                    <div class="col-3 col-md-3">
                      <div class="quarter">Quarter 1</div>
                      <div class="quarter-value">{{ data2020.Q1.length }}</div>
                    </div>
                    <div class="col-3 col-md-3">
                      <div class="quarter">Quarter 2</div>
                      <div class="quarter-value">{{ data2020.Q2.length }}</div>
                    </div>
                    <div class="col-3 col-md-3">
                      <div class="quarter">Quarter 3</div>
                      <div class="quarter-value">{{ data2020.Q3.length }}</div>
                    </div>
                    <div class="col-3 col-md-3">
                      <div class="quarter">Quarter 4</div>
                      <div class="quarter-value">{{ data2020.Q4.length }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <div class="container">
                  <div class="row quarters">
                    <span class="year">2021</span>
                    <div class="col-3 col-md-3">
                      <div class="quarter">Quarter 1</div>
                      <div class="quarter-value">{{ data2021.Q1.length }}</div>
                    </div>
                    <div class="col-3 col-md-3">
                      <div class="quarter">Quarter 2</div>
                      <div class="quarter-value">{{ data2021.Q2.length }}</div>
                    </div>
                    <div class="col-3 col-md-3">
                      <div class="quarter">Quarter 3</div>
                      <div class="quarter-value">{{ data2021.Q3.length }}</div>
                    </div>
                    <div class="col-3 col-md-3">
                      <div class="quarter">Quarter 4</div>
                      <div class="quarter-value">{{ data2021.Q4.length }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="thick-divider mb-4"></div>

            <div class="row">
              <div class="col-8 col-md-6 section-title mb-4">Check for Similar Names</div>
            </div>

            <div class="section_sub_title">
              Please select the name that is correct
            </div>

            <div class="weak-divider mb-1"></div>

            <div v-if="status.similars.length == 0"
                 class="mt-5 mb-5">
              <p class="text-center">
                No similar names found or all of them are now merged
              </p>
            </div>

            <div v-for="(item, index) in status.similars"
                 :key="index"
                 class="wrapper">
              <div :class="index > 0 ? 'mt-5' : ''" class="row">
                <div class="col-md-12">
                  <div v-for="(name, idx) in status.similars[index].similars"
                       :key="idx"
                       class="similar-name-item">
                    <input
                        @click="enableMergeSimilars($event)"
                        :id="`radiobox-${index}-${idx}`"
                        :name="`name-${index}`"
                        :value="name.name"
                        class="radio-custom" type="radio"
                        @change="OnCheckedRadioBtn($event)">
                    <label @click="enableMergeSimilars($event)"
                           :for="`radiobox-${index}-${idx}`" class="radio-custom-label">{{ name.name }}</label>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-12">
                  <button
                      @click="mergeSimilars($event)"
                      type="button"
                      class="btn btn-outline btn-merge disabled">
                    Merge
                  </button>
                </div>
              </div>
            </div>

            <div class="thick-divider mt-5"></div>

            <div class="row mt-5">
              <div class="col-8 col-md-6 section-title mb-4">Check for Duplicate Entries</div>
              <div class="col-4 col-md-6 text-end">
                <a href="#"
                   @click="deleteAll"
                   @click.prevent class="btn-del-all">Delete All</a>
              </div>
            </div>

            <div class="section_sub_title">
              Select the data those are redundant and then merge
            </div>

            <div v-if="status.duplicated.length == 0"
                 class="duplicate-entries mt-5 mb-5">
              <p class="text-center">
                No duplicates found or all of them are now merged
              </p>
            </div>

            <div class="duplicate-entries mt-4">
              <div v-for="(item, index) in status.duplicated" class="wrapper" :key="index">
                <div class="grey-header">
                  <div class="row">
                    <div class="col-md-6">Name</div>
                    <div class="col-md-3">Check Date</div>
                    <div class="col-md-3">Gross</div>
                  </div>
                </div>
                <div v-for="(dup, idx) in status.dupsArray.filter((o)=> {return o.name == item.name})"
                     :key="idx"
                     class="table-row"
                     :class="{highlighted: item.name === dup.name  && item.date === dup.date && item.amount === dup.amount}">
                  <div class="row">
                    <div class="col-md-6 duplicate-name form-check">
                      <input
                          @click="enableMerge($event)"
                          :id="`checkbox-${index}-${idx}`"
                          :data-index="dup.index"
                          class="checkbox-custom" type="checkbox"/>
                      <label
                          @click="enableMerge($event)"
                          :for="`checkbox-${index}-${idx}`"
                          class="checkbox-custom-label"
                      >{{ dup.name }}</label>
                    </div>
                    <div class="col-md-3 duplicate-name">{{ dup.date }}</div>
                    <div class="col-md-3 duplicate-name">{{ dup.amount }}</div>
                  </div>
                </div>
                <div class="mt-4 mb-4">
                  <button
                      type="button"
                      class="btn-outline default me-3">
                    Skip
                  </button>
                  <button
                      @click="mergeDuplicated($event)"
                      type="button"
                      class="btn-outline btn-merge disabled">
                    Merge
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="btn-wrapper mt-3 d-flex justify-content-end">
            <button @click="OnBtnClickedPayroll()"
                    v-if="step > 1"
                    type="button" class="submit me-3">
              Back
            </button>
            <button v-if="step == 2" @click="saveFilteredPayroll()"
                    type="button" class="submit me-3">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="tutorial" aria-hidden="true" aria-labelledby="tutoriaLabel" tabindex="-1">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="tutoriaLabel">Tutorial</h5>
            <button type="button" class="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="subtitle">You've selected 4 names. Please select the primary name</div>
            <div class="step-title mt-3">
              <span class="red">Step 1 :</span> Open your Payroll Register
            </div>
            <div class="step-sub mt-3">
              Please note the example listed here from Quickbooks.
            </div>

            <div class="step mt-3">
              <div class="grey-header mb-1">
                <div class="row">
                  <div class="col-md-2">
                    Employee
                  </div>
                  <div class="col-md-2">
                    Check Info
                  </div>
                  <div class="col-md-1"></div>
                  <div class="col-md-2">
                    Payroll Details
                  </div>
                </div>
              </div>
              <div class="grey-header mt-2">
                <div class="row">
                  <div class="col-m-4">Name</div>
                  <div class="col-m-4">SSN</div>
                  <div class="col-m-4">Pay Start</div>
                  <div class="col-m-4">Pay End</div>
                  <div class="col-m-4">Chk Date</div>
                  <div class="col-m-4">Chk</div>
                  <div class="col-m-4">Hours</div>
                  <div class="col-m-4">Gross</div>
                  <div class="col-m-4">Fed W/H</div>
                  <div class="col-m-4">Soc Sec</div>
                  <div class="col-m-4">Med Care</div>
                  <div class="col-m-4">Addl</div>
                  <div class="col-m-4">State W/H</div>
                  <div class="col-m-4">401(K)</div>
                  <div class="col-m-4">Net Pay</div>
                </div>
              </div>
              <div class="table-row">
                <div class="row">
                  <div class="col-m-4">Jon Doe</div>
                  <div class="col-m-4">*** ** 3333</div>
                  <div class="col-m-4">03/31/20</div>
                  <div class="col-m-4">03/31/20</div>
                  <div class="col-m-4">03/31/20</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-0.01</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-0.01</div>
                </div>
              </div>
              <div class="table-row">
                <div class="row">
                  <div class="col-m-4"></div>
                  <div class="col-m-4"></div>
                  <div class="col-m-4">03/01/20</div>
                  <div class="col-m-4">03/31/20</div>
                  <div class="col-m-4">03/31/20</div>
                  <div class="col-m-4">147</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">4,243.60</div>
                  <div class="col-m-4">-1,879.67</div>
                  <div class="col-m-4">-263.10</div>
                  <div class="col-m-4">-61.54</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-414.29</div>
                  <div class="col-m-4">-1,625.00</div>
                  <div class="col-m-4">-</div>
                </div>
              </div>
              <div class="table-row">
                <div class="row">
                  <div class="col-m-4"></div>
                  <div class="col-m-4"></div>
                  <div class="col-m-4">04/01/20</div>
                  <div class="col-m-4">04/30/20</div>
                  <div class="col-m-4">04/30/20</div>
                  <div class="col-m-4">147</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">4,243.60</div>
                  <div class="col-m-4">-1,879.67</div>
                  <div class="col-m-4">-263.10</div>
                  <div class="col-m-4">-61.54</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-414.29</div>
                  <div class="col-m-4">-1,625.00</div>
                  <div class="col-m-4">-</div>
                </div>
              </div>
              <div class="table-row total">
                <div class="row">
                  <div class="col-m-4"></div>
                  <div class="col-m-4"></div>
                  <div class="col-m-4"></div>
                  <div class="col-m-4"></div>
                  <div class="col-m-4"></div>
                  <div class="col-m-4">TOTAL</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">127,518.09</div>
                  <div class="col-m-4">-74,343.66</div>
                  <div class="col-m-4">-7,906.12</div>
                  <div class="col-m-4">-1,809.42</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-10,919.32</div>
                  <div class="col-m-4">-32,500.00</div>
                  <div class="col-m-4">-</div>
                </div>
              </div>

              <div class="table-row">
                <div class="row">
                  <div class="col-m-4">Jon Doe</div>
                  <div class="col-m-4">*** ** 2222</div>
                  <div class="col-m-4">03/31/20</div>
                  <div class="col-m-4">03/31/20</div>
                  <div class="col-m-4">03/31/20</div>
                  <div class="col-m-4">148</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">1,759.61</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-109.10</div>
                  <div class="col-m-4">-25.51</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-1,625.00</div>
                  <div class="col-m-4">-</div>
                </div>
              </div>
              <div class="table-row">
                <div class="row">
                  <div class="col-m-4"></div>
                  <div class="col-m-4"></div>
                  <div class="col-m-4">03/01/20</div>
                  <div class="col-m-4">03/31/20</div>
                  <div class="col-m-4">03/31/20</div>
                  <div class="col-m-4">152</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">1,759.61</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-109.10</div>
                  <div class="col-m-4">-61.54</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-1,625.00</div>
                  <div class="col-m-4">-</div>
                </div>
              </div>
              <div class="table-row">
                <div class="row">
                  <div class="col-m-4"></div>
                  <div class="col-m-4"></div>
                  <div class="col-m-4">04/01/20</div>
                  <div class="col-m-4">04/30/20</div>
                  <div class="col-m-4">04/30/20</div>
                  <div class="col-m-4">156</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">3,819.62</div>
                  <div class="col-m-4">-1,902.42</div>
                  <div class="col-m-4">-236.82</div>
                  <div class="col-m-4">-55.38</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-1,625.00</div>
                  <div class="col-m-4">-</div>
                </div>
              </div>
              <div class="table-row total">
                <div class="row">
                  <div class="col-m-4"></div>
                  <div class="col-m-4"></div>
                  <div class="col-m-4"></div>
                  <div class="col-m-4"></div>
                  <div class="col-m-4"></div>
                  <div class="col-m-4">TOTAL</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">99,634.27</div>
                  <div class="col-m-4">-55,099.91</div>
                  <div class="col-m-4">-6,177.32</div>
                  <div class="col-m-4">-1,444.69</div>
                  <div class="col-m-4">-</div>
                  <div class="col-m-4">-4,412..31</div>
                  <div class="col-m-4">-32,500.04</div>
                  <div class="col-m-4">-</div>
                </div>
              </div>
            </div>
            <div class="step mt-5">
              <div class="divider"></div>
            </div>
            <div class="step-title mt-3">
              <span class="red">Step 2 :</span> Clean the data as shown below
            </div>
            <div class="step-sub-2 mt-3">
              Please watch the video for tips and instructions.
            </div>
            <div class="step mt-3">
              <div class="row">
                <div class="offset-md-2 col-md-8">
                  <div class="grey-header">
                    <div class="row">
                      <div class="col-md-4">
                        Name
                      </div>
                      <div class="col-md-4">
                        Check Date
                      </div>
                      <div class="col-md-4">
                        Gross
                      </div>
                    </div>
                  </div>
                  <div class="table-row">
                    <div class="row">
                      <div class="col-md-4">Jon Doe</div>
                      <div class="col-md-4">03/31/20</div>
                      <div class="col-md-4">-</div>
                    </div>
                  </div>
                  <div class="table-row">
                    <div class="row">
                      <div class="col-md-4"></div>
                      <div class="col-md-4">03/31/20</div>
                      <div class="col-md-4">4,243.60</div>
                    </div>
                  </div>
                  <div class="table-row">
                    <div class="row">
                      <div class="col-md-4"></div>
                      <div class="col-md-4">04/30/20</div>
                      <div class="col-md-4">4,243.60</div>
                    </div>
                  </div>
                  <div class="table-row">
                    <div class="row">
                      <div class="col-md-4">Jon Doe</div>
                      <div class="col-md-4">03/31/20</div>
                      <div class="col-md-4">-</div>
                    </div>
                  </div>
                  <div class="table-row">
                    <div class="row">
                      <div class="col-md-4"></div>
                      <div class="col-md-4">03/31/20</div>
                      <div class="col-md-4">4,243.60</div>
                    </div>
                  </div>
                  <div class="table-row">
                    <div class="row">
                      <div class="col-md-4"></div>
                      <div class="col-md-4">04/30/20</div>
                      <div class="col-md-4">4,243.60</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {inject, ref} from 'vue';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import {statusStore} from '@/store/status'
import {userStore} from '@/store/user'
import * as fuzz from 'fuzzball'

const changeStatus = inject('changeStatus')

dayjs.extend(quarterOfYear)
dayjs.extend(weekOfYear)
const status = statusStore()
const user = userStore()
const uuid = user.appID
status.getSavedPayroll(uuid)

const payroll = ref([
  {
    'index': 0,
    'name': '',
    'quarter': '',
    'date': '',
    'amount': '',
    'type': '',
    'outOfRange': false,
    'keep': true,
    'familyMember': false,
    'bindIdentifier': ''
  }
])

const source = ref([
  {
    'index': 0,
    'name': '',
    'quarter': '',
    'date': '',
    'amount': '',
    'type': '',
    'outOfRange': false,
    'keep': true,
    'familyMember': false,
    'bindIdentifier': ''
  }
])

const step = ref(1)
const allChecked = ref(false)
console.log(allChecked);
const isLoading = ref(false)
status.payroll = source.value
status.health = source.value
status.retirement = source.value
status.fmla = source.value

const dateRange = {
  min: '2020-03-13',
  max: '2022-01-01'
}

const startDate = '2020-06-30'

const data2020 = {
  Q1: [],
  Q2: [],
  Q3: [],
  Q4: []
}

const data2021 = {
  Q1: [],
  Q2: [],
  Q3: [],
  Q4: []
}

function onPaste(e, step) {
  const elem = e.target
  const key = elem.dataset.key
  const clipboardData = e.clipboardData || window.clipboardData
  if (!clipboardData) {
    console.error('Clipboard copy/paste not supported')
    return
  }
  const str = clipboardData.getData('text')
  let arr = []
  let reg = /\r\n/g
  if (reg.test(str)) {
    arr = str.split('\r\n')
  }
  reg = /\n/g
  if (reg.test(str)) {
    arr = str.split('\n')
  }

  if (arr.length == 0) {
    return
  }
  source.value.pop()
  arr.forEach((str, index) => {
    if (index == (arr.length - 1)) {
      return
    }
    let item = str.split('\t')

    let date = item[1]
    let outOfRange = false
    date = dayjs(date).format('YYYY-MM-DD')

    if (date < dateRange.min || date > dateRange.max) {
      outOfRange = true
    }

    let year = dayjs(date).year()
    let quarter = dayjs(date).quarter()
    let strQuarter = year + ' Q' + quarter

    let o = {
      'index': index,
      'name': item[0].trim(),
      'quarter': strQuarter,
      'date': item[1].trim(),
      'amount': item[2].trim(),
      'type': '',
      'outOfRange': outOfRange,
      'keep': true,
      'familyMember': false,
      'bindIdentifier': user.appID
    }
    source.value.push(o)
  })


  // Remove first entry and replace with current output
  setTimeout(function () {
    switch (key) {
      case 'name':
      case 'date':
      case 'amount':
        elem.value = source.value[0][key]
        break
    }
  }, 50)

  switch (step) {
    case 1:
      payroll.value = source.value
      status.payroll = source.value
      break
    case 3:
      status.health = source.value
      break
    case 4:
      status.retirement = source.value
      break
    case 5:
      status.fmla = source.value
      break
  }
}


function formatNumber(n) {
  if (!n) {
    return
  }
  let reg = /[$]/g
  let str = n.replace(reg, '')
  str = str.replace(/\s+/g, '')
  return '$ ' + str
}


function getSource(step) {
  let src = []
  const uuid = user.appID
  switch (step) {
    case 1:
      // status.getSource(uuid, 'payroll')
      src = status.payroll
      break
    case 3:
      status.getSource(uuid, 'health')
      src = status.health
      break
    case 4:
      status.getSource(uuid, 'retirement')
      src = status.retirement
      break

    case 5:
      status.getSource(uuid, 'fmla')
      src = status.fmla
      break
  }
  return src
}

function clearTable(st) {
  source.value.length = 0
  const empty = {
    'index': 0,
    'name': '',
    'date': '',
    'quarter': '',
    'amount': '',
    'type': '',
    'outOfRange': false,
    'keep': true,
    'familyMember': false,
    'bindIdentifier': ''
  }

  step.value = 1

  switch (st) {
    case 1:
      status.payrollClear = true
      status.payroll.length = 0
      status.payroll.push(empty)
      break
    case 3:
      status.healthClear = true
      status.health.length = 0
      status.health.push(empty)
      break

    case 4:
      status.retirementClear = true
      status.retirement.length = 0
      status.retirement.push(empty)
      break

    case 5:
      status.fmlaClear = true
      status.fmla.length = 0
      status.fmla.push(empty)
      break
  }
}

function handleKeyEvent(e) {
  let elem = e.target;
  let key = e.key;
  let index = Number(elem.dataset.index)
  let keyName = elem.dataset.key
  let names = document.querySelectorAll('.td-names');
  let dates = document.querySelectorAll('.td-dates');
  let wages = document.querySelectorAll('.td-wages');

  switch (key) {
    case 'ArrowDown':
      switch (keyName) {
        case 'date':
          if (index < dates.length - 1) {
            dates[index + 1].focus()
          }
          break

        case 'amount':
          if (index < wages.length - 1) {
            wages[index + 1].focus()
          }
          break

        case 'name':
          if (index < names.length - 1) {
            names[index + 1].focus()
          }
          break
      }
      break

    case 'ArrowUp':
      switch (keyName) {
        case 'date':
          if (index > 0) {
            dates[index - 1].focus()
          }
          break

        case 'amount':
          if (index > 0) {
            wages[index - 1].focus()
          }
          break

        case 'name':
          if (index > 0) {
            names[index - 1].focus()
          }
          break
      }
      break

    case 'ArrowRight':
      switch (keyName) {
        case 'name':
          dates[index].focus()
          break

        case 'date':
          wages[index].focus()
          break
      }
      break

    case 'ArrowLeft':
      switch (keyName) {
        case 'amount':
          dates[index].focus()
          break

        case 'date':
          names[index].focus()
          break
      }
      break
    case 'Delete':
      elem.value = ''
      break
  }
}


function actions(step) {
  changeStatus(true)
  switch (step) {
    case 2:
      payroll.value = status.payroll
      getDuplicates()
      getSimilars()
      getEntriesReport()
      changeStatus(false)
      break
    case 3:
      savePayroll()
      clearTable(0)
      status.payroll = payroll.value
      changeStatus(false)
      break

    case 4:
      saveSheetData(step)
      combineData(step)
      clearTable(0)
      status.health = source.value
      changeStatus(false)
      break

    case 5:
      saveSheetData(step)
      combineData(step)
      clearTable(0)
      status.retirement = source.value
      changeStatus(false)
      break
  }
}

function getDuplicates() {
  let src = payroll.value
  let buffer = []
  status.duplicated.length = 0
  status.dupsArray.length = 0
  src.forEach((e) => {
    if (src.filter((o) => {
      return o.name == e.name &&
          o.date == e.date &&
          o.amount == e.amount
    }).length > 1) {
      let dup = e.name
      if (buffer.includes(dup)) {
        return
      }
      buffer.push(dup)
      status.duplicated.push(e)
    }
  })

  let dups = status.duplicated

  src.forEach((e) => {
    if (dups.filter((o) => {
      return o.name == e.name &&
          o.date == e.date &&
          o.amount == e.amount
    }).length > 0) {
      status.dupsArray.push(e)
    }
  })
}

function getSimilars() {
  let src = payroll.value
  let buffer = []
  let single = []
  src.forEach((e) => {
    if (src.filter((o) => {
      return o.name == e.name
    }).length > 0) {
      let dup = e.name
      if (buffer.includes(dup)) {
        return
      }
      buffer.push(dup)
      single.push(e)
    }
  })

  buffer.length = 0
  src.forEach((e) => {
    let find = single.filter((o) => {
      let ratio = fuzz.token_set_ratio(e.name, o.name)
      if (ratio > 80) {
        o.ratio = ratio
        return o
      }
    })
    buffer.push(find)
  })

  let sims = buffer.filter((o) => {
    if (o.length > 1) {
      return o
    }
  })

  status.similars.length = 0
  buffer.length = 0
  sims.forEach((e) => {
    let o = {}
    let name = e[0].name
    if (buffer.includes(name)) {
      return
    }
    o.name = e[0].name
    o.similars = e
    status.similars.push(o)
    buffer.push(name)
  })
}

function enableMerge(e) {
  let elem = e.target;
  if (elem.checked) {
    let wrapper = elem.parentElement?.parentElement?.parentElement?.parentElement
    let btnDisabled = wrapper?.querySelector('button.btn-merge.disabled')
    btnDisabled?.classList.remove('disabled')
  }

  if (!elem.checked) {
    let wrapper = elem.parentElement?.parentElement?.parentElement?.parentElement
    let checkedInputs = wrapper?.querySelectorAll('input:checked')
    if (checkedInputs?.length == 0) {
      let btnDisabled = wrapper?.querySelector('button.btn-merge')
      btnDisabled?.classList.add('disabled')
    }
  }
}

function enableMergeSimilars(e) {
  let elem = e.target
  if (elem.checked) {
    let wrapper = elem.parentElement?.parentElement?.parentElement?.parentElement
    let btnDisabled = wrapper?.querySelector('button.btn-merge.disabled')
    btnDisabled?.classList.remove('disabled')
  }

  if (!elem.checked) {
    let wrapper = elem.parentElement?.parentElement?.parentElement?.parentElement
    let checkedInputs = wrapper?.querySelectorAll('input:checked')
    if (checkedInputs?.length == 0) {
      let btnDisabled = wrapper?.querySelector('button.btn-merge')
      btnDisabled?.classList.add('disabled')
    }
  }
}

function mergeDuplicated(e) {
  let elem = e.target;

  if (elem.classList.contains('disabled')) {
    return;
  }

  let wrapper = elem.parentElement?.parentElement
  let checkedInput = wrapper?.querySelector('input:checked')
  let index = Number(checkedInput.dataset.index)

  let dup = payroll.value.filter((o) => {
    return o.index == index
  })[0]

  status.duplicated = status.duplicated.filter((o) => {
    return o.index != index
  })

  status.payroll = status.payroll.filter((item) => {
    if (item.name != dup.name ||
        item.date != dup.date ||
        item.amount != dup.amount) {
      return item;
    }
  })
  status.payroll.push(dup)

  let checked = wrapper?.querySelectorAll('input:checked');
  let btnDisabled = wrapper?.querySelector('.btn-merge')
  btnDisabled?.classList.add('disabled')
  checked.forEach((n) => {
    n.checked = false
  })

  payroll.value.map((o) => {
    if (o.name == dup.name &&
        o.date == dup.date &&
        o.amount == dup.amount) {
      o.keep = false
    }
  })
}

function mergeSimilars(e) {
  let elem = e.target
  if (elem.classList.contains('disabled')) {
    return
  }
  let wrapper = elem.parentElement?.parentElement?.parentElement
  let checkedInput = wrapper?.querySelector('input:checked')
  let checkedIndex = Number(checkedInput.name.split('-').pop())
  let selectedName = checkedInput.value
  let pulled = status.similars[checkedIndex].similars
  let similars = []

  pulled.forEach((o) => {
    similars.push(o.name)
  })

  payroll.value.forEach((o) => {
    if (similars.includes(o.name)) {
      o.name = selectedName
    }
  })

  status.similars = status.similars.filter((o, index) => {
    if (index != checkedIndex) {
      return o
    }
  })

  let checked = document.querySelectorAll('input:checked')
  checked.forEach((n) => {
    n.checked = false
  })

  let btnDisabled = wrapper?.querySelector('.btn-merge')
  btnDisabled?.classList.add('disabled')
}


function OnBtnClickedPayroll() {
  payroll.value = source.value
  status.payroll = source.value
  getDuplicates()
  getSimilars()
  getEntriesReport()
}


function saveFilteredPayroll() {
  payroll.value.sort((a, b) => a.index - b.index)
  status.payroll.sort((a, b) => a.index - b.index)
  actions(2)
  if (status.duplicated.length > 0) return;
  user.savePayrolls(changeStatus)
  step.value = 1


  // // Get loans if any in new application format
  // let pppLoans = user.formAnswers.pppLoans
  // let loansPPPAmounts = []
  // let loansBreakdown = []
  //
  // // Sort loans breakdown by date, oldest first
  // pppLoans.sort((a, b) => {
  //   return Date.parse(a.loanDate) - Date.parse(b.loanDate)
  // })
  //
  // if (pppLoans.length > 0) {
  //   for (let i = 0; i < 3; i++) {
  //     if (!pppLoans[i]) {
  //       break
  //     }
  //     let arr = []
  //     arr.push(pppLoans[i].utilities)
  //     arr.push(pppLoans[i].mortgage)
  //     arr.push(pppLoans[i].rent)
  //     arr.push(pppLoans[i].refinance)
  //     arr.push(pppLoans[i].healthcare)
  //     arr.push(pppLoans[i].debt)
  //     let sum = arr.reduce((a, b) => a + b, 0)
  //     loansPPPAmounts.push(pppLoans[i].pppLoan)
  //     loansBreakdown.push(sum)
  //   }
  // }
  //
  // if (loansBreakdown.length > 0) {
  //   payroll.value.map((o) => {
  //     o.ppp1 = loansPPPAmounts[0]
  //     o.ppp1Date = pppLoans[0].loanDate
  //     o.ppp1BreakDown = loansBreakdown[0]
  //   })
  // }
  //
  // if (loansBreakdown.length > 1) {
  //   payroll.value.map((o) => {
  //     o.ppp2 = loansPPPAmounts[1]
  //     o.ppp2Date = pppLoans[1].loandate
  //     o.ppp2BreakDown = loansBreakdown[1]
  //   })
  // }
  //
  // if (loansBreakdown.length > 2) {
  //   payroll.value.map((o) => {
  //     o.ppp3 = loansPPPAmounts[2]
  //     o.ppp3Date = pppLoans[2].loandate
  //     o.ppp3BreakDown = loansBreakdown[1]
  //   })
  // }
  //
  // payroll.value.map((o) => {
  //   delete o.createdAt
  //   delete o.updatedAt
  //   delete o.deleteDate
  //   delete o.ratio
  //   delete o.uuid
  //
  //   let d = dayjs(o.date).format('YYYY-MM-DD')
  //   let year = dayjs(d).year()
  //   let quarter = dayjs(d).quarter()
  //   let week = dayjs(d).week()
  //
  //   o.week = week
  //   o.quarter = quarter
  //   o.year = year
  // })
  //
  // const uuid = user.appID
  // user.savePayroll(uuid, payroll.value)
}

source.value = status.savedPayroll
payroll.value = status.savedPayroll
status.payroll = status.savedPayroll


status.$subscribe((mutation, state) => {
  mutation.type
  mutation.storeId
  if (!state.payrollClear) {
    source.value = state.savedPayroll
    payroll.value = state.savedPayroll
  }
})


function getEntriesReport() {
  let src = payroll.value
  data2020.Q1.length = 0
  data2020.Q2.length = 0
  data2020.Q3.length = 0
  data2020.Q4.length = 0
  data2021.Q1.length = 0
  data2021.Q2.length = 0
  data2021.Q3.length = 0
  data2021.Q4.length = 0

  src.forEach((o) => {
    let d = dayjs(o.date).format('YYYY-MM-DD')
    let year = dayjs(d).year()
    let quarter = dayjs(d).quarter()
    if (!o.keep) {
      return
    }
    if (year == 2020) {
      switch (quarter) {
        case 1:
          data2020.Q1.push(o)
          break

        case 2:
          data2020.Q2.push(o)
          break

        case 3:
          data2020.Q3.push(o)
          break

        case 4:
          data2020.Q4.push(o)
          break
      }
    }
    if (year == 2021) {
      switch (quarter) {
        case 1:
          data2021.Q1.push(o)
          break

        case 2:
          data2021.Q2.push(o)
          break

        case 3:
          data2021.Q3.push(o)
          break

        case 4:
          data2021.Q4.push(o)
          break
      }
    }
  })
}

function updateJSON(e, step) {
  let elem = e.target
  let index = Number(elem.dataset.index)
  let keyName = elem.dataset.key;
  let date = ''
  switch (keyName) {
    case 'name':
      if (source.value.length > 1) {
        source.value[index]['name'] = elem.value
        return
      }
      switch (step) {
        case 1:
          status.payroll[index]['name'] = elem.value
          break

        case 3:
          status.health[index]['name'] = elem.value
          break

        case 4:
          status.retirement[index]['name'] = elem.value
          break

        case 5:
          status.fmla[index]['name'] = elem.value
          break
      }
      break

    case 'date':
      date = dayjs(elem.value).format('MM/DD/YYYY')
      if (source.value.length > 1) {
        source.value[index]['date'] = date
      }
      date = dayjs(elem.value).format('YYYY-MM-DD')
      switch (step) {
        case 1:
          status.payroll[index]['date'] = elem.value
          if (date < dateRange.min || date > dateRange.max) {
            elem.parentElement?.classList.add('out-of-range')
            status.payroll[index]['outOfRange'] = true
          }

          if (date > dateRange.min && date < dateRange.max) {
            elem.parentElement?.classList.remove('out-of-range')
            status.payroll[index]['outOfRange'] = false
          }
          break

        case 3:
          status.health[index]['date'] = elem.value
          if (date < dateRange.min || date > dateRange.max) {
            elem.parentElement?.classList.add('out-of-range')
            source.value[index]['outOfRange'] = true
          }

          if (date > dateRange.min && date < dateRange.max) {
            elem.parentElement?.classList.remove('out-of-range')
            source.value[index]['outOfRange'] = false
          }
          break

        case 4:
          status.retirement[index]['date'] = elem.value
          if (date < dateRange.min || date > dateRange.max) {
            elem.parentElement?.classList.add('out-of-range')
            source.value[index]['outOfRange'] = true
          }

          if (date > dateRange.min && date < dateRange.max) {
            elem.parentElement?.classList.remove('out-of-range')
            source.value[index]['outOfRange'] = false
          }
          break

        case 5:
          status.fmla[index]['date'] = elem.value
          if (date < dateRange.min || date > dateRange.max) {
            elem.parentElement?.classList.add('out-of-range')
            source.value[index]['outOfRange'] = true
          }

          if (date > dateRange.min && date < dateRange.max) {
            elem.parentElement?.classList.remove('out-of-range')
            source.value[index]['outOfRange'] = false
          }
          break
      }
      break

    case 'amount':
      if (source.value.length > 1) {
        source.value[index]['amount'] = elem.value
      }
      switch (step) {
        case 1:
          status.payroll[index]['amount'] = elem.value
          break

        case 3:
          status.health[index]['amount'] = elem.value
          break

        case 4:
          status.retirement[index]['amount'] = elem.value
          break

        case 5:
          status.fmla[index]['amount'] = elem.value
          break
      }
      break
  }
}


function deleteAll() {
  let src = payroll.value
  let buffer = []
  src.forEach((e) => {
    if (src.filter((o) => {
      return o.name == e.name &&
          o.date == e.date &&
          o.amount == e.amount
    }).length > 1) {
      let dup = e.name
      if (buffer.includes(dup)) {
        e.keep = false
        return
      }
      buffer.push(dup)
      e.keep = true
    }
  })
  payroll.value = src
  status.duplicated.length = 0
}

function printPayroll() {
  console.log(status.payroll);
}

function savePayroll() {
  let src = status.payroll.length > 1 ? status.payroll : source.value
  let buffer = []
  if (status.duplicated.length > 0) {
    src.forEach((e) => {
      if (src.filter((o) => {
        return o.name == e.name &&
            o.date == e.date &&
            o.amount == e.amount &&
            !o.keep
      }).length > 1) {
        let dup = e.name
        if (buffer.includes(dup)) {
          return
        }
        e.keep = true
        buffer.push(dup)
      }
    })
  }
  payroll.value = src.filter((o) => {
    return o.keep && !o.outOfRange
  })
  status.savedPayroll = payroll.value
}

function combineData(step) {
  payroll.value.forEach((o) => {
    let d = dayjs(o.date).format('YYYY-MM-DD')
    o.year = dayjs(d).year()
    o.week = dayjs(d).week()
    o.quarter = dayjs(d).quarter()
  })
  source.value.forEach((o) => {
    let d = dayjs(o.date).format('YYYY-MM-DD')
    o.year = dayjs(d).year()
    o.week = dayjs(d).week()
    o.quarter = dayjs(d).quarter()
  })
  let src = source.value.filter((o) => {
    return !o.outOfRange
  })
  let buffer = []
  payroll.value.forEach((e) => {
    let find = src.filter((o) => {
      if (o.year == e.year &&
          o.quarter == e.quarter) {
        let ratio = fuzz.ratio(e.name, o.name)
        if (ratio > 80) {
          o.ratio = ratio
          return o
        }
      }
    })
    if (find.length > 0) {
      if (buffer.includes(e.name)) {
        return
      }
      buffer.push(e.name)
      find.sort((a, b) => {
        return b.ratio - a.ratio
      })
      let sum = 0
      switch (step) {
        case 4:
          sum = findReducer(find)
          e.health = sum
          break

        case 5:
          sum = findReducer(find)
          e.retirement = sum
          break

        case 6:
          sum = findReducer(find)
          e.fmla = sum
          break
      }
    } else {
      switch (step) {
        case 2:
          e.health = 0
          e.retirement = 0
          e.fmla = 0
          break

        case 4:
          e.health = 0
          break

        case 5:
          e.retirement = 0
          break

        case 6:
          e.fmla = 0
          break
      }
    }
  })

  if (step == 3) {
    // Save last sheet of data
    const uuid = user.appID
    saveSheetData(6)
    savePayroll()

    // Get loans if any old application form format
    let loanPeriods = user.formAnswers.getLoanPeriods
    let loanQuarters = loanPeriods.map((item, index) => {
      let o = {}
      if (item === 'Q2-Q3-2020') {
        o.index = index
        o.range = [
          {year: 2020, quarter: 2},
          {year: 2020, quarter: 3}
        ]
        o.loan = 0
      }

      if (item === 'Q4-2020-Q1-2021') {
        o.index = index
        o.range = [
          {year: 2020, quarter: 4},
          {year: 2021, quarter: 1}
        ]
        o.loan = 0
      }

      if (item === 'Q2-Q3-2021') {
        o.index = index
        o.range = [
          {year: 2021, quarter: 2},
          {year: 2021, quarter: 3}
        ]
        o.loan = 0
      }
      return o
    })
    let loans = user.formAnswers.getLoans
    loanQuarters.map((o, index) => {
      o.loan = loans[index]
    })

    // Get loans if any in new application format
    let pppLoans = user.formAnswers.pppLoans
    let loansPPPAmounts = []
    let loansBreakdown = []

    // Sort loans breakdown by date, oldest first
    pppLoans.sort((a, b) => {
      return Date.parse(a.loanDate) - Date.parse(b.loanDate)
    })
    if (pppLoans.length > 0) {
      for (let i = 0; i < 3; i++) {
        if (!pppLoans[i]) {
          break
        }

        let arr = []
        arr.push(pppLoans[i].utilities)
        arr.push(pppLoans[i].mortgage)
        arr.push(pppLoans[i].rent)
        arr.push(pppLoans[i].refinance)
        arr.push(pppLoans[i].healthcare)
        arr.push(pppLoans[i].debt)

        let sum = arr.reduce((a, b) => a + b, 0)
        loansPPPAmounts.push(pppLoans[i].pppLoan)
        loansBreakdown.push(sum)
      }
    }

    let src = status.payroll.length > 1 ? status.payroll : payroll.value
    src.forEach((o) => {
      let d = dayjs(o.date).format('YYYY-MM-DD')
      o.quarter = dayjs(d).quarter()
    })
    src.sort(function (a, b) {
      let nameA = a.name.toLowerCase()
      let nameB = b.name.toLowerCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    let summary = []
    let buffer = []
    let quarter = [
      {name: '2020 Q1', year: 2020, q: 1},
      {name: '2020 Q2', year: 2020, q: 2},
      {name: '2020 Q3', year: 2020, q: 3},
      {name: '2020 Q4', year: 2020, q: 4},
      {name: '2021 Q1', year: 2021, q: 1},
      {name: '2021 Q2', year: 2021, q: 2},
      {name: '2021 Q3', year: 2021, q: 3},
      {name: '2021 Q4', year: 2021, q: 4}
    ]

    let payrollSrc = status.savedPayroll
    let payrollFilter = payrollSrc.filter((o) => {
      let d = dayjs(o.date).format('YYYY-MM-DD')
      o.wage = currencyParse(o.amount)
      if (d > startDate) {
        return o
      }
    })

    let payrollSum = payrollFilter.reduce((a, b) => {
      return a + b.wage
    }, 0)

    src.forEach((e, index) => {
      if (buffer.includes(e.name)) {
        return
      }

      quarter.forEach((q) => {
        let arr = src.filter((o) => {
          return o.year == q.year &&
              o.quarter == q.q &&
              o.name == e.name
        })

        if (arr.length > 0) {
          let res = arrayReducer(arr, e.name, q.name, index, e.date)
          loanQuarters.forEach((i, index) => {
            if (res.y == i.range[0].year && res.q == i.range[0].quarter) {
              switch (index) {
                case 0:
                  res.ppp1 = Number(i.loan)
                  break

                case 1:
                  res.ppp2 = Number(i.loan)
                  break

                case 2:
                  res.ppp3 = Number(i.loan)
                  break
              }
            }

            if (res.y == i.range[1].year && res.q == i.range[1].quarter) {
              switch (index) {
                case 0:
                  res.ppp1 = Number(i.loan)
                  break

                case 1:
                  res.ppp2 = Number(i.loan)
                  break

                case 2:
                  res.ppp3 = Number(i.loan)
                  break
              }
            }
          })

          delete res.q
          delete res.y

          if (loansBreakdown.length > 0) {
            res.ppp1 = loansPPPAmounts[0]
            res.sumBreakdown1 = loansBreakdown[0]
          }

          if (loansBreakdown.length > 1) {
            res.ppp2 = loansPPPAmounts[1]
            res.sumBreakdown2 = loansBreakdown[1]
          }

          if (loansBreakdown.length > 2) {
            res.ppp3 = loansPPPAmounts[2]
            res.sumBreakdown3 = loansBreakdown[2]
          }

          res.WPPP1 = Number(payrollSum.toFixed(2))

          res.bindIdentifier = user.appID
          summary.push(res)
        }
      })

      buffer.push(e.name)
    })
    // console.log(summary)
    user.saveSummaryData(uuid, summary)
  }
}

function findReducer(arr) {
  arr.map((o) => {
    let str = o.amount
    let value = str ? str.replace(/\D/g, '') : ''
    let n = 0
    let dec = []
    let decStr = ''
    let reg = /[()]/g
    if (reg.test(str)) {
      value = '-' + value
    }
    n = Number(value) / 100
    if (o.amount) {
      dec = o.amount.toString().split('.')
      decStr = dec && dec.length > 0 ? dec[1] : false
      if (decStr && decStr.length == 1) {
        n = Number(value) / 10
      }
    }
    reg = /[,.]/g
    if (!reg.test(str)) {
      n = Number(value)
    }
    o.wage = isNaN(n) ? 0 : n
  })

  let sum = arr.map((o) => o.wage).reduce((a, b) => a + b, 0).toFixed(2)
  return sum
}

function arrayReducer(q, name, quarter, index, date) {
  let arr = q.map((o) => {
    let reg = /[()]/g
    let str = o.amount
    let value = str ? str.replace(/\D/g, '') : ''
    let n = 0
    let dec = []
    let decStr = ''
    if (reg.test(str)) {
      value = '-' + value
    }
    n = Number(value) / 100
    if (o.amount) {
      dec = o.amount.toString().split('.')
      decStr = dec && dec.length > 0 ? dec[1] : false
      if (decStr && decStr.length == 1) {
        n = Number(value) / 10
      }
    }
    reg = /[,.]/g
    if (!reg.test(str)) {
      n = Number(value)
    }
    o.payrollVal = isNaN(n) ? 0 : n

    str = o.health
    value = str ? str.replace(/\D/g, '') : ''
    reg = /[()]/g
    if (reg.test(str)) {
      value = '-' + value
    }
    n = Number(value) / 100
    if (o.health) {
      dec = o.health.toString().split('.')
      decStr = dec && dec.length > 0 ? dec[1] : false
      if (decStr && decStr.length == 1) {
        n = Number(value) / 10
      }
    }
    reg = /[,.]/g
    if (!reg.test(str)) {
      n = Number(value)
    }
    o.healthVal = isNaN(n) ? 0 : n

    str = o.retirement
    value = str ? str.replace(/\D/g, '') : ''
    reg = /[()]/g
    if (reg.test(str)) {
      value = '-' + value
    }
    n = Number(value) / 100
    if (o.retirement) {
      dec = o.retirement.toString().split('.')
      decStr = dec && dec.length > 0 ? dec[1] : false
      if (decStr && decStr.length == 1) {
        n = Number(value) / 10
      }
    }
    reg = /[,.]/g
    if (!reg.test(str)) {
      n = Number(value)
    }
    o.retirementVal = isNaN(n) ? 0 : n

    str = o.fmla
    value = str ? str.replace(/\D/g, '') : ''
    reg = /[()]/g
    if (reg.test(str)) {
      value = '-' + value
    }
    n = Number(value) / 100
    if (o.fmla) {
      dec = o.fmla.toString().split('.')
      decStr = dec && dec.length > 0 ? dec[1] : false
      if (decStr && decStr.length == 1) {
        n = Number(value) / 10
      }
    }
    reg = /[,.]/g
    if (!reg.test(str)) {
      n = Number(value)
    }
    o.fmlaVal = isNaN(n) ? 0 : n
    return o
  })

  let res = {}
  let n = 0
  res.index = index
  res.name = name
  res.quarter = quarter
  res.familyMember = false

  let savedSummary = status.summary
  let find = savedSummary.filter((o) => {
    return o.name == name
  })
  if (find.length > 0) {
    res.familyMember = find[0].familyMember
  }

  n = arr.map((o) => o.payrollVal).reduce((a, b) => a + b, 0).toFixed(2)
  res.payroll = Number(n)
  n = arr.map((o) => o.healthVal).reduce((a, b) => a + b, 0).toFixed(2)
  res.health = Number(n)
  n = arr.map((o) => o.retirementVal).reduce((a, b) => a + b, 0).toFixed(2)
  res.retirement = Number(n)
  n = arr.map((o) => o.fmlaVal).reduce((a, b) => a + b, 0).toFixed(2)
  res.fmla = Number(n)

  res.y = dayjs(date).year()
  res.q = dayjs(date).quarter()

  return res
}

function saveSheetData(step) {
  let type = ''
  let uuid = user.appID
  let data = []
  switch (step) {
    case 2:
      type = 'PAYROLL'
      if (status.payroll.length > 1) {
        payroll.value = status.payroll
      }
      payroll.value.map((o) => {
        o.type = type
        delete o.createdAt
        delete o.deleteDate
        delete o.updatedAt
        delete o.uuid
      })
      data = payroll.value
      status.payroll = payroll.value
      break

    case 4:
      type = 'HEALTH'
      if (status.health.length > 1) {
        source.value = status.health
      }
      source.value.map((o) => {
        o.type = type
        delete o.createdAt
        delete o.deleteDate
        delete o.updatedAt
        delete o.uuid
      })
      status.health = source.value
      data = source.value
      break

    case 5:
      type = 'RETIRMENT'
      if (status.retirement.length > 1) {
        source.value = status.retirement
      }
      source.value.map((o) => {
        o.type = type
        delete o.createdAt
        delete o.deleteDate
        delete o.updatedAt
        delete o.uuid
      })
      status.retirement = source.value
      data = source.value
      break

    case 6:
      type = 'FLMA'
      if (status.fmla.length > 1) {
        source.value = status.fmla
      }
      source.value.map((o) => {
        o.type = type
        delete o.createdAt
        delete o.deleteDate
        delete o.updatedAt
        delete o.uuid
      })
      status.fmla = source.value
      data = source.value
      break
  }

  // Check if any data was added if not, do not save
  let empty = data.filter((o) => {
    return o.name == '' &&
        o.date == '' &&
        o.amount == ''
  })
  if (empty.length > 0) {
    return
  }
  user.saveSheetData(uuid, data)
}

function currencyParse(numb) {
  let str = numb
  let value = str ? str.replace(/\D/g, '') : ''
  let reg = /[-]/g
  if (reg.test(str)) {
    value = '-' + value
  }
  let n = Number(value) / 100
  let dec = numb.toString().split('.')
  let decStr = dec && dec.length > 0 ? dec[1] : false
  if (decStr && decStr.length == 1) {
    n = Number(value) / 10
  }
  reg = /[,.]/g
  if (!reg.test(str)) {
    n = Number(value)
  }
  n = isNaN(n) ? 0 : n
  return n
}

const isCheckedRadioBtn = ref(false)

function OnCheckedRadioBtn(e) {
  isCheckedRadioBtn.value = e.target.checked
}
</script>
<style scoped lang="scss" src="./payroll-sheet.scss"></style>