<template>
  <Layout>
    <div class="container-fluid pt-5" style="margin-bottom: 120px">
      <companyName v-bind:name="company.company.name"/>
      <btn-back/>
      <div class="title mb-2">
        Payroll
      </div>

      <div class="row">
        <div class="col-md-12 col-lg-12">
          <BootStrapAlert/>
        </div>

        <div class="col-md-12 col-lg-12">
          <PayrollProviders/>

          <div class="mt-5 mb-4 thick-divider"></div>

          <div class="provide-your-pay-data mb-4">
            Provide Your Pay Data
          </div>

          <div class="progress-documents">
            Upload your pay data
          </div>

          <div class="upload-file mt-3"
               @drop="dropFiles"
               @dragover.prevent
               @dragenter.prevent
               @click="uploadFile()">
            <img :src="require('/src/assets/images/download.png')" alt="download">&nbsp;UPLOAD FILE
          </div>

          <div id="drop-area" class="d-none">
            <input class="form-control" name="files[]" type="file" multiple/>
          </div>

          <div class="text-provider mt-2">
            Upload the document and we can mange it for you.
          </div>

          <div class="text-provider font-weight-600">
            Note : This may delay the process
          </div>

          <div class="text-provider mt-2 font-weight-600">
            PDF upload can further increase lead times 4 weeks or longer.
          </div>

          <div v-if="user.uploadError" class="alert alert-danger mt-3">
            <span>
                Upload file error, please notice only the following file formats are allowed, csv,
                xls, xlsx
            </span>
          </div>

          <div v-if="user.userFiles.length > 0" class="file-list mt-3">
            <p><strong>Payroll files</strong></p>
            <ul class="list-unstyled">
              <li v-for="(item, index) in user.userFiles" :key="index"
                  class="alert alert-primary">
                <span class="me-3">{{ index + 1 }}</span>
                <span class="me-3">{{ item.name }}</span>
                <div :class="user.uploadReady?'d-none':''" class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <span :class="user.uploadReady?'':'d-none'" class="text-primary upload-ready">
                                          <font-awesome-icon icon="fa-solid fa-check"/>
                                      </span>
                <button :data-id="item.uuid" @click="deleteFile"
                        :class="user.uploadReady?'':'d-none'" class="btn btn-delete float-end">
                  <i class="fa-solid fa-trash-can"></i>
                </button>
              </li>
            </ul>
          </div>

          <div class="optional-container  mt-4">
            <div class="weak-divider"></div>
            <span class="optional">
                Optional
            </span>
          </div>
        <PayrollSheet/>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import Layout from "@/layouts/main";
import BootStrapAlert from "@/components/utils/Alert";
import btnBack from "@/components/btn-back/btnBack";
import PayrollProviders from "@/components/payroll/payroll-providers/payroll-providers";
import PayrollSheet from "@/components/payroll/payroll-sheet/payroll-sheet"
import companyName from "@/components/company-name/company-name";
import {companyStore} from "@/store/company";
const company = companyStore();

import $ from 'jquery';
import {userStore} from "@/store/user";
const user = userStore();


function dropFiles (e) {
  e.preventDefault()
  let dt = e.dataTransfer
  let files = dt.files
  let fd = new FormData()

  // Validate file extension
  if (files) {
    for (let i = 0; i < files?.length; i++) {
      let fileExtension = files[i].name.split('.')
      let tempExtension = fileExtension[1].toLowerCase()

      if (!user.validExtensions.includes(tempExtension)) {
        user.uploadError = true
        return
      }
    }
  }

  // If no error returned proceed to prepare payload and upload
  if (files) {
    for (let i = 0; i < files?.length; i++) {
      const o = {
        name: files[i].name,
        uuid: 'new'
      }

      const existingFiles = user.userFiles.map((o) => o.name)
      const reducer = (name) => {
        if (!existingFiles.includes(name)) {
          fd.append('files', files[i], name)
          user.userFiles.push({
            name: name,
            uuid: 'new'
          })
          return
        }

        let fileName = name.split('.')[0].toLowerCase()
        let fileExtension = name.split('.')[1].toLowerCase()

        fileName += '_1.'
        fileName += fileExtension
        reducer(fileName)
      }
      reducer(o.name)
    }
  }

  user.uploadFiles(fd, 'Payroll')
}


function uploadFile() {
  console.log('clicked')
  $('#drop-area input')[0].click()

  $('#drop-area input').on('change', function(e){
    e.stopImmediatePropagation()
    const input = document.querySelector('#drop-area input')  | null
    let fd = new FormData()
    const files = input?.files

    // Validate file extension
    if (files) {
      for (let i = 0; i < files?.length; i++) {
        let fileExtension = files[i].name.split('.')
        let tempExtension = fileExtension[1].toLowerCase()

        if (!user.validExtensions.includes(tempExtension)) {
          user.uploadError = true
          return
        }
      }
    }

    // If no error returned proceed to prepare payload and upload
    if (files) {
      for (let i = 0; i < files?.length; i++) {
        const o = {
          name: files[i].name,
          uuid: 'new'
        }

        const existingFiles = user.userFiles.map((o) => o.name)
        const reducer = (name) => {
          if (!existingFiles.includes(name)) {
            fd.append('files', files[i], name)
            user.userFiles.push({
              name: name,
              uuid: 'new'
            })
            return;
          }

          let fileName = name.split('.')[0].toLowerCase();
          let fileExtension = name.split('.')[1].toLowerCase();

          fileName += '_1.';
          fileName += fileExtension;
          reducer(fileName);
        }
        reducer(o.name);
      }
    }

    user.uploadFiles(fd, 'Payroll')
    let uploadInput = document.querySelector('#drop-area input');
    uploadInput.value = '';
  })
}


function deleteFile(e) {
  let elem = e.target.parentElement
  let applicationID = this.$router.params.id
  let file = {
    applicadionID: applicationID,
    fileID: elem.dataset.id
  }

  $(elem).parent().find('.spinner-border').toggleClass('d-none')
  $(elem).parent().find('.upload-ready').toggleClass('d-none')
  user.deleteFile(file)
}

</script>
<style scoped lang="scss" src="./payroll.scss"></style>