<template>
  <div class="select-provider">
    Please Select your payroll software provider
  </div>

  <div class="row mt-4 mb-4">
    <div class="col-12 col-md-4 offset-md-4">
      <div class="payroll-provider">Payroll Software Providers</div>
      <div class="btn-group d-block">
        <button type="button"
                class="btn btn-danger dropdown-toggle btn-provider"
                style="width: 100%"
                data-bs-toggle="dropdown" aria-expanded="false">
          Payroll Software Providers
        </button>
        <ul class="dropdown-menu" style="width: 100%; height: 430px; overflow-y: scroll">
          <li>
            <a v-for="(item, pos) in providers"
               v-bind:key="pos"
               @click.prevent
               data-bs-toggle="modal" href="#provider"
               @click="OnSelectedProvider(pos)"
               class="dropdown-item d-flex justify-content-between pt-2 pb-2">
              <img :src="require('@/assets/images/providers/'+item.icon + '.png')"
                   height="30"
                   class="me-2" :alt="item.text">
              {{item.text}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <p class="text-provider">
    If your payroll software provider is not listed here or you use a CPA, please look
    for or request the documents that would be needed to do a 941 filing in an
    EXCEL format.
  </p>
  <p class="text-provider">
    Proper format includes Full Name, Check Date, and Gross Pay information in a single row,
    for every paycheck in the years of 2020 and 2021. Failing to provide the proper format
    can
    result in longer lead times and rejection of documents.
  </p>

  <div class="modal fade" id="provider" aria-hidden="true" aria-labelledby="providerLabel" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" ref="providerLabel" id="providerLabel">
            <img :src="require('@/assets/images/providers/'+selectedProvider.icon + '.png')"
                 height="30"
                 class="me-2" :alt="selectedProvider.text">
            {{selectedProvider.text}}
          </h5>
          <button type="button" class="btn-close" aria-label="Close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <div class="logo text-center">
            <img :src="require('@/assets/images/providers/'+selectedProvider.icon + '.png')"
                 height="100"
                 class="me-2" :alt="selectedProvider.text">
          </div>

          <div class="provider" v-show="selectedProvider.provider === 'quickbooks'">
            <h4>QuickBooks Online Payroll</h4>
            <p><b>1.</b> Go to <b>Reports.</b></p>
            <p><b>2.</b> Scroll down to the <b>Payroll</b> section, then select <b>Payroll Summary by Employee.</b></p>
            <p><b>3.</b> Set a date range, then select <b>Apply.</b></p>
            <p><b>4.</b> If you want to include or hide a specific detail in this report, select <b>Customize</b> then <b>Run report.</b></p>
            <h4>QuickBooks Desktop Payroll</h4>
            <p><b>1.</b> Go to <b>Reports</b> and select <b>Employees and Payroll.</b></p>
            <p><b>2.</b> Select <b>Payroll Summary.</b></p>
            <p><b>3.</b> <b>Adjust the date range appropriately.</b></p>
            <p><b>4.</b> In the <b>Show Columns</b> dropdown, select <b>Employee.</b></p>
            <p><b>5.</b> Select <b>Customize Report</b> tab, then <b>Filters</b> tab.</p>
            <p><b>6.</b> In the <b>Choose</b> Filter section, scroll down to select <b>Name</b> from the filter list.</p>
            <p><b>7.</b> From the <b>Name</b> dropdown, select the employee.</p>
            <p><b>8.</b> Select <b>OK.</b></p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'adp'">
            <h4>ADP Instructions for Pulling a detailed payroll journal</h4>
            <p><b>1.</b> Log in to your ADP account with your username and password.</p>
            <p><b>2.</b> Navigate to the <b>“Reports”</b> tab on the main menu.</p>
            <p><b>3.</b> Click custom report</p>
            <p><b>4.</b> Chose <b>“ERC2”</b> option</p>
            <p><b>5.</b> Click – revise report</p>
            <p><b>6.</b> Check off these additional fields:</p>
            <p class="sub">● Payroll deduction description</p>
            <p class="sub">● Payroll check date</p>
            <p class="sub">● Payroll net play</p>
            <p class="sub">● Payroll check number</p>
            <p class="sub">● Payroll tax category</p>
            <p class="sub">● Payroll tax jurisdiction</p>
            <p class="sub">● Payroll earning description</p>
            <p class="sub">● Net pay amount</p>
            <p><b>7.</b> Click on <b>“Run Report”</b> to generate the payroll summary report by employee for the ERC.</p>
            <p><b>8.</b> Once the report is generated Export it to Excel.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'primepay'">
            <p>1. Open a web browser and navigate to the PrimePay website at https://primepay.com.</p>
            <p>2. Click on the "Login" button in the top right corner of the page.</p>
            <p>3. Enter your PrimePay username and password, then click "Login."</p>
            <p>4. Once you are logged in, click on the “Reports” tab in the top navigation menu.</p>
            <p>5. In the dropdown menu that appears, click "Payroll Reports."</p>
            <p>6. On the Payroll Reports page, scroll down to the "Employee Pay History" section and click on the "View Report" button.</p>
            <p>7. Set the report parameters to include only W-2 employees and a date range from January 1, 2020 to December 31, 2021.</p>
            <p>8. Select “Gross Pay” as the data element to include in the report.</p>
            <p>9. Choose to group the report by employee.</p>
            <p>10. Select the “Date of Pay” option to include in the report.</p>
            <p>11. Click on the “Generate Report” button to create the report.</p>
            <p>12. Once the report is generated, click on the “Export” button and select “Export to Excel” to save it as an Excel file.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'rippling'">
            <p>1. Log in to your Rippling account with your username and password.</p>
            <p>2. Navigate to the “Reports” tab on the main menu.</p>
            <p>3. Click on "Journal Reports" from the list of available reports.</p>
            <p>4. Choose Yearly date range for the payroll period (2020 and 2021), and select any other relevant filters such as specific employees (W-2 non family members).</p>
            <p>5. Click on “Run Report” to generate the payroll summary report by employee for the ERC.</p>
            <p>6. Once the report is generated Export it to Excel.</p>
            <p><a href="https://youtube.com/clip/UgkxPn6Z_vb0uy2_Dvv0Z0vNOHP9X8_l4h-n">https://youtube.com/clip/UgkxPn6Z_vb0uy2_Dvv0Z0vNOHP9X8_l4h-n</a></p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'paycom'">
            <p>1. Log in to your Paycom account with your username and password.</p>
            <p>2. Navigate to the “Reports” tab on the main menu.</p>
            <p>3. Click on search and search for “Cares Act Employee Retention Credit Report”</p>
            <p>4. Click on Excel to export to excel</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'aps'">
            <p>1. Log in to APS and navigate to the “Reports” section.</p>
            <p>2. Click on the "Payroll Summary" report from the list of available reports.</p>
            <p>3. Select the date range as “01/01/2020” to “12/31/2021” using the date range filter.</p>
            <p>4. Filter the report to include only W-2 employees by selecting "W-2" in the "Employee Type" filter.</p>
            <p>5. Select "Employee" in the "Group By" filter to create a report that groups data by employee.</p>
            <p>6. Check the "Date of Pay" checkbox to include the date of pay in the report.</p>
            <p>7. Click on the “Generate Report” button to create the report.</p>
            <p>8. Once the report is generated, click on the "Export" button and select “Excel” as the file type.</p>
            <p>9. Save the exported file to your desired location.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'patriot'">
            <p>1. Log in to your Patriot account.</p>
            <p>2. Click on the “Reports” tab from the main menu.</p>
            <p>3. On the following page, choose “Payroll details report” from the list of options under the Payroll section.</p>
            <p>4. You will be directed to a new page where you can enter the date range for the payroll report. Please input the payroll date range as follows: January 1, 2020 to December 31, 2021.</p>
            <p>5. Under employees select W2 employees only.</p>
            <p>6. If you have multiple locations set up in your Patriot account, you can filter the report by location.</p>
            <p>7. Once you have selected the desired date range and location, click on “Run Report”.</p>
            <p>8. The report will be generated, and you will be able to view it on the screen.</p>
            <p>9. To download or print the report, click on print report.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'bamboohr_traxpayroll'">
            <h3>Option 1</h3>
            <p>1. Log in to your BambooHR and navigate to TRAXPayroll.</p>
            <p>2. Select the Reports option from the top menu.</p>
            <p>3. In the Reports section, select custom report.</p>
            <p>4. Select the fields last name, first name, effective date and pay rate or gross.</p>
            <p>5. Click on more and select excel to download on excel.</p>
            <h4>Option 2</h4>
            <p>1. Log in to your BambooHR and navigate to TRAXPayroll.</p>
            <p>2. Select the Reports option from the top menu.</p>
            <p>3. In the Reports section, select Salary history.</p>
            <p>4. Click on more and select excel to download on excel.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'ukg'">
            <p>1. Log in to your UKG Pro account and go to the Payroll module.</p>
            <p>2. Click on the "Payroll Processing" tab to access the Payroll Processing page.</p>
            <p>3. Click on the "Payroll Gateway" button to access the Payroll Gateway page.</p>
            <p>4. On the Payroll Gateway page, click on the "Payroll Reports" link.</p>
            <p>5. In the list of available reports, locate the "Detailed Payroll Report" and click on it.</p>
            <p>6. Select the payroll period for which you want to create the register (2020 and 2021) and choose only W2 employees whose payroll information you want to include in the register.</p>
            <p>7. Preview the report to ensure that the information is accurate.</p>
            <p>8. If the information is correct, click on the “Generate Report” button to generate the detailed payroll register.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'paychex'">
            <p><b>
              Paychex provides a variety of payroll services and software solutions, so the specific instructions for creating a payroll summary report by employee for the Employee Retention Credit (ERC) may vary depending on your specific Paychex plan and settings. However, here are some general steps to create this type of report in Paychex:
            </b></p>
            <p>1. Log in to your Paychex account.</p>
            <p>2. Click on “Reports” from the main menu.</p>
            <p>3. Click on “Payroll” from the list of report categories.</p>
            <p>4. Select "Payroll Summary" from the list of available reports.</p>
            <p>5. In the report parameters section, select the date range of “01/01/2020” to “12/31/2021”.</p>
            <p>6. Under the "Employee Selection" section, select "W-2 Employees" from the drop-down menu.</p>
            <p>7. Click on the "Columns" tab and select the "Date of Pay" column to be included in the report.</p>
            <p>8. Click on the “Generate Report” button to create the report.</p>
            <p>9. Once the report is generated, click on the "Export" button to export the report to Excel.</p>
            <p>
              <a href="https://www.paychex.com/tax-services/employee-retention-tax-credit-services*They do it themselves for their clients*">
                https://www.paychex.com/tax-services/employee-retention-tax-credit-services*They do it themselves for their clients*
              </a>
            </p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'gusto'">
            <p>1. Log in to your Gusto account.</p>
            <p>2. From the main menu, click on “Reports”.</p>
            <p>3. Select “Payroll Journal builder” from the sub-menu.</p>
            <p>4. Under the Columns section, select the “check date”, “Gross earnings”, “employee first and last name” column to include it in the report.</p>
            <p>5. Under the “Check dates” section, select “2020” and later one for “2021”.</p>
            <p>6. Under the “Select employees” section, choose “W-2” employees.</p>
            <p>7. Click on “excel” under Format.</p>
            <p>8. Once the report is generated, click on the “Run report” button to export the report to Excel or if you are not able export it as a csv or pdf file.</p>
            <p>
              <a href="https://www.youtube.com/watch?v=5qMAO0YIdxc">
                https://www.youtube.com/watch?v=5qMAO0YIdxc
              </a>
            </p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'greyhr'">
            <p>1. Log in to your greytHR account.</p>
            <p>2. Go to the “Reports” tab from the main menu.</p>
            <p>3. Click on the "Reports Gallery" sub-tab.</p>
            <p>4. Click on " Salary Register ".</p>
            <p>5. Select "From Jan 01 2020 to Dec 31 2020" to create a new payroll statement.</p>
            <p>6. Select “gross” and “date of pay”.</p>
            <p>7. Include only W2 employees.</p>
            <p>8. Click on options and select excel format, click save and then generate.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'isolved'">
            <p>1. Log in to your Isolved account using your credentials.</p>
            <p>2. Navigate to the “Reporting” tab, which should be located on the main menu.</p>
            <p>3. Select the “Client Report” option from the dropdown menu.</p>
            <p>4. Scroll down the list of available reports until you find the "CARES Act Retention Credit Report" for companies with less than 100 employees.</p>
            <p>5. Select the appropriate time frame for the report, which should be from 2020 to 2021.</p>
            <p>6. Choose the “Excel” format for the report.</p>
            <p>7. Click on the “Generate Report” button to generate the report.</p>
            <p>8. Once the report has been generated, you can access it by going to the "My Report Queue" section.</p>
            <p>9. Find the report you just generated and click on the "view report" button to download it to your computer.</p>

            <p>They can call Account Manager from Isolved to get the ERC report, they help you throw the phone and get the documents for you.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'onpay'">
            <p>1. Log in to your OnPay account and navigate to the “Reports” module.</p>
            <p>2. Under “Payroll Reports” select “Payroll Summary Report.”</p>
            <p>3. Set the date range for the report by selecting “Custom” under the “Date Range” dropdown menu, and then selecting the start and end dates for the report (i.e., January 1, 2020 to December 31, 2021).</p>
            <p>4. Under the “Filters” section, select “W-2” as the employee type to include only W-2 employees in the report.</p>
            <p>5. Click the “Generate Report” button to generate the report.</p>
            <p>6. Once the report is generated, click the “Export” button and select “Excel” as the file format to export the report to an Excel spreadsheet.</p>
            <p>
              <a href="https://help.onpay.com/hc/en-us/articles/201810609-How-to-run-a-Payroll-Register-Report">
                https://help.onpay.com/hc/en-us/articles/201810609-How-to-run-a-Payroll-Register-Report
              </a>
            </p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'justworks'">
            <p>1. Log in to your Justworks account.</p>
            <p>2. Click on Reports under Company in the navigation menu.</p>
            <p>3. Select “Custom Payroll Report” from the list of available reports.</p>
            <p>4. Click “ADD DATA” and select Summary payroll information.</p>
            <p>5. In the report filters, select the date range from January 2020 to December 2021.</p>
            <p>6. Under “Advanced Filters” select “W-2 Employees” only.</p>
            <p>7. Click the “Update preview” button to generate the report.</p>
            <p>Once the report is generated, click on Download as .csv</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'paycor'">
            <p>1. Log in to your Paycor account and navigate to the “Reports” tab.</p>
            <p>2. select “Payroll Report”.</p>
            <p>3. Select "Custom Date Range" and set the date range to be from January 1, 2020, to December 31, 2021.</p>
            <p>4. Under the " Staff column" section, select only W-2 employees.</p>
            <p>5. In the "Columns to Include" section, select “Pay Date” and any other relevant columns you wish to include in your report.</p>
            <p>6. Click on the "Generate" button to generate the report.</p>
            <p>7. Once the report is generated, click on the down arrow fou0nd in the upper right-hand corner, to download it as a .csv file.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'workday'">
            <p>1. Log in to your Workday account and click on the “Reports” icon on your homepage.</p>
            <p>2. In the search bar, type “Payroll Summary” and select “Payroll Summary - Worker” from the list of results.</p>
            <p>3. On the “Payroll Summary - Worker” report page, click on the "Edit" button.</p>
            <p>4. In the "Criteria" section, select "Custom Period" and set the date range to be from January 1, 2020, to December 31, 2021.</p>
            <p>5. In the “Employee Type” section, select “W-2 employees” only.</p>
            <p>6. In the “Columns” section, select “Pay Date” and any other relevant columns you wish to include in your report.</p>
            <p>7. Click on the “Run” button to generate the report.</p>
            <p>8. Once the report is generated, click on the “Export” button to export the report to Excel.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'surepayroll'">
            <p>1. Log in to your SurePayroll account and navigate to the “Reports” tab.</p>
            <p>2. Under the “Payroll” section, select “Payroll Summary Report”.</p>
            <p>3. Select "Custom Date Range" and set the date range to be from January 1, 2020, to December 31, 2021.</p>
            <p>4. Under the "Filter By" section, select “Employee Type” and choose “W-2 employees” only.</p>
            <p>5. In the "Columns to Display" section, select “Pay Date”, employee name, gross pay, and any other relevant information.</p>
            <p>6. Click on the “Generate Report” bu0tton to generate the report.</p>
            <p>7. Once the report is generated, click on the “Export” button to export the report to Excel.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'deel'">
            <p>1. Log in to your Deel account and navigate to the “Payroll” tab.</p>
            <p>2. Click on “Reports” and select “Payroll Register” from the dropdown menu.</p>
            <p>3. Choose the pay period for which you want to generate the report.</p>
            <p>4. Select the employees for whom you want to generate the report or leave the default selection to generate the report for all employees.</p>
            <p>5. Choose the type of report you want to generate - PDF, Excel or CSV.</p>
            <p>6. Click on “Generate Report” and wait for the report to be generated.</p>
            <p>7. Once the report is generated, you can download it and save it for your records.</p>

            <p>*Mostly used to pay International employees*</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'ceridian'">
            <p>1. Log in to your Ceridian Dayforce account and navigate to the “Reports” tab.</p>
            <p>2. Under the “Payroll”  section, select "Payroll Register".</p>
            <p>3. Select “Custom” for the report period and set the date range to be from January 1, 2020, to December 31, 2021.</p>
            <p>4. Under the “Filter” section, select “Employee Type” and choose “W-2 employees” only.</p>
            <p>5. In the “Columns” section, select “Pay Date” and any other relevant columns you wish to include in your report.</p>
            <p>6. Click on the “Run Report” button to generate the report.</p>
            <p>7. Once the report is generated, click on the “Export” button to export the report to Excel.</p>

            <p><a href="https://www.powerpay.ca/en/docs/SBG.pdf">https://www.powerpay.ca/en/docs/SBG.pdf</a></p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'paylocity'">
            <p>1. Log in to your Paylocity account and navigate to the Reports section.</p>
            <p>2. Click on "Standard Reports" and select “Payroll” from the drop-down menu.</p>
            <p>3. Select the date range for the report (January 1, 2020 - December 31, 2020 for the 2020 report and January 1, 2021 - December 31, 2021 for the 2021 report).</p>
            <p>4. Choose the type of report you need, such as the "W2 Employee Summary" or “Payroll Register” report.</p>
            <p>5. Click on “Generate Report” and wait for the report to be generated.</p>
            <p>6. Once the report is ready, you can either view it online or download it in a variety of formats, such as Excel or PDF.</p>
            <p>7. Repeat the process for the other year if you need to generate a report for both 2020 and 2021.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'fingercheck'">
            <p>1. Log in to your Fingercheck Payroll account.</p>
            <p>2. Click on the “Reports” tab at the top of the page.</p>
            <p>3. Select “Payroll” from the drop-down menu.</p>
            <p>4. In the “Payroll” section, select “Employer Retention Credit” and enter the date range from January 1, 2020, to December 31, 2021.</p>
            <p>5. In the “Filters” section, select “W-2 employees” under the “Employee Type” drop-down menu.</p>
            <p>6. Click the “Generate Report” button.</p>
            <p>7. Select the relevant columns for the report, including the employee name, pay date, gross pay, and any other relevant information.</p>
            <p>8. Once the report is generated, click the “Export to Excel” button to export the report to an Excel file.</p>
            <p>9. The report will include the date of pay for each employee.</p>

            <p>Note: The exact steps may vary slightly depending on the version of Fingercheck Payroll that you are using.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'inova'">
            <p>1. Log in to your Inova Payroll account.</p>
            <p>2. Select the “Reports” tab from the top menu.</p>
            <p>3. In the left-hand menu, select “Payroll Reports”.</p>
            <p>4. Select “Payroll Summary” from the available report options.</p>
            <p>5. Set the date range to include only the year 2020 to 2021.</p>
            <p>6. Under the "Employee Options" section, select “W-2 employees” only.</p>
            <p>7. Choose "Individual Employee Summary" as the report type.</p>
            <p>8. Select the relevant columns for the report, including the employee name, pay date, gross pay, and any other relevant information.</p>
            <p>9. Click “Run Report” to generate the payroll summary report.</p>
            <p>10. Once the report is generated, click the “Export” button to export the report to Excel.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'pilot'">
            <p>1. Log in to your Pilot account.</p>
            <p>2. Click on the “Payroll”  tab in the top menu.</p>
            <p>3. Select the “Reports” tab from the submenu.</p>
            <p>4. Click on “Payroll Summary” from the available report options.</p>
            <p>5. Set the date range to include only the year 2020 to 2021.</p>
            <p>6. Under the "Employee Options" section, select “W-2 employees” only.</p>
            <p>7. Choose "Individual Employee Summary" as the report type.</p>
            <p>8. Select the relevant columns for the report, including the employee name, pay date, gross pay, and any other relevant information.</p>
            <p>9. Click the “Generate Report” button to generate the payroll summary report.</p>
            <p>10. Once the report is generated, click the “Export” button to export the report to Excel.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'trinet'">
            <p>1. Log in to your TriNet account.</p>
            <p>2. Go to the “Payroll section” and select the “Reports tab.”</p>
            <p>3. Select the Payroll Summary Report option from the list of available reports.</p>
            <p>4. Choose the date range for the report. In this case, select January 1, 2020, to December 31, 2021.</p>
            <p>5. Under the "Employee Selection" section, select the “W-2” option.</p>
            <p>6. Click on the “Run Report” button to generate the report.</p>
            <p>7. Once the report is generated, click on the “Export” button to export it to Excel.</p>

            <p>Cant get that information on their platform</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'akriviahcm'">
            <p>1. Log in to your Akrivia HCM account and go to the Payroll module.</p>
            <p>2. Select “Payroll Summary Report” from the available report options.</p>
            <p>3. Set the date range to 01/01/2020 to 12/31/2021 to include only W-2 employees for the specified period.</p>
            <p>4. Choose the “Employee” option from the “Group By” dropdown menu to generate the report by employee.</p>
            <p>5. Select the relevant columns for the report, including the employee name, pay date, gross pay, and any other relevant information.</p>
            <p>6. Click the “Generate” button to create the report.</p>
            <p>7. Once the report is generated, click the “Export to Excel” button to save it in Excel format.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'epayhcm'">
            <p>1. Log in to your EPAY HCM account and go to the “Payroll” module.</p>
            <p>2. Select “Reports” from the menu, then choose “Payroll Summary” from the available report options.</p>
            <p>3. Set the date range to 01/01/2020 to 12/31/2021 to include only W-2 employees for the specified period.</p>
            <p>4. Choose the “Employee” option from the “Group By” dropdown menu to generate the report by employee.</p>
            <p>5. Select the relevant columns for the report, including the employee name, pay date, gross pay, and any other relevant information.</p>
            <p>6. Click the “Run Report” button to create the report.</p>
            <p>7. Once the report is generated, click the “Export to Excel” button to save it in Excel format.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'experianemployerservices'">

          </div>
          <div class="provider" v-show="selectedProvider.provider === 'hourly'">
            <p>1. Log in to your Hourly account and go to the “Payroll”  module.</p>
            <p>2. Select “Reports” from the menu, then choose “Payroll Summary” from the available report options.</p>
            <p>3. Set the date range to 01/01/2020 to 12/31/2021 to include only W-2 employees for the specified period.</p>
            <p>4. Choose the “Employee” option from the “Group By” dropdown menu to generate the report by employee.</p>
            <p>5. Select the relevant columns for the report, including the employee name, pay date, gross pay, and any other relevant information.</p>
            <p>6. Click the “Run Report” button to create the report.</p>
            <p>7. Once the report is generated, click the “Export to Excel” button to save it in Excel format.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'zenefits'">
            <p>1. Log in to your Zenefits account and select the “Payroll”  from the dashboard.</p>
            <p>2. Click on the “Reports” tab at the top of the page.</p>
            <p>3. Under the “Payroll Reports” section, click on the “Payroll Summary Report” option.</p>
            <p>4. Select the date range of January 1, 2020, to December 31, 2021, to include only W-2 employees for the specified time period.</p>
            <p>5. Choose the employees for which you want to generate the report.</p>
            <p>6. Once you have set all the filters select “Excel” in file type as the file format.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === '247hrm'">
            <p>1. Log in to your 247HRM account.</p>
            <p>2. Navigate to the Payroll module.</p>
            <p>3. Click on the Reports tab.</p>
            <p>4. Select "Payroll Summary Report by Employee" from the list of available reports.</p>
            <p>5. In the report parameters, select “W-2 employees” and set the date range to “01/01/2020” to “12/31/2021”.</p>
            <p>6. Select “Date of Pay” as one of the columns to be included in the report.</p>
            <p>7. Click on the “Generate Report” button to generate the report.</p>
            <p>8. Once the report is generated, click on the “Export to Excel” button to export the report to an Excel file.</p>
            <p>9. Save the Excel file to your computer or any desired location.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'cavuhcm'">
            <p>1. Log in to CAVU HCM and navigate to the Payroll module.</p>
            <p>2. Select the "Payroll Summary" report option.</p>
            <p>3. In the report settings, select the date range for the report (2020-01-01 to 2021-12-31) and filter for W-2 employees only.</p>
            <p>4. Select the "Gross Pay" field to include in the report.</p>
            <p>5. Choose to display the "Date of Pay" field in the report.</p>
            <p>6. Run the report and preview the results to ensure that it meets your requirements.</p>
            <p>7. Once satisfied with the report, export it to Excel by selecting the "Export" option and choosing “Excel” as the file format.</p>
            <p>8. Save the exported file to a desired location.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'toasttab.com'">
            <p>1. Log in to your ToastTab.com account and navigate to the Payroll module.</p>
            <p>2. Look for an option to generate reports or analytics related to payroll, and select it.</p>
            <p>3. Set the date range to January 1, 2020, to December 31, 2021.</p>
            <p>4. Filter the report to include only W-2 employees.</p>
            <p>5. Select the Employee option in the Group By field to group the data by employee.</p>
            <p>6. Choose the Pay Date option in the Sort By field to sort the report by date of pay.</p>
            <p>Export the report to Excel by looking for an export button or downloading option.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'sagehr'">
            <p>1. Log in to your Sage HR account.</p>
            <p>2. Click on the “Employees and payroll” tab in the top menu.</p>
            <p>3. Select “Reports” from the left-hand menu.</p>
            <p>4. Choose “Payroll Check Register” from the list of available reports.</p>
            <p>5. Set the report parameters to include only W-2 employees and a date range from January 1, 2020 to December 31, 2021.</p>
            <p>6. Click on the “Generate Report” button to create the report.</p>
            <p>7. Once the report is generated, click on the “Export to Excel” button to save it as an Excel file.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'hrone'">
            <p>1. Log in to HROne and navigate to the Payroll module.</p>
            <p>2. Click on “Reports” in the left-hand menu.</p>
            <p>3. Click on “Payroll Summary” from the list of available reports.</p>
            <p>4. Select “Employee” from the drop-down menu for the “Group By” option.</p>
            <p>5. Choose the date range for the report, selecting only 2020 to 2021.</p>
            <p>6. Under "Employee Selection," select “W-2 employees” only.</p>
            <p>7. Under "Columns to Display," select “Gross Pay” and “Date of Pay”.</p>
            <p>8. Click “Generate Report” to create the report.</p>
            <p>9. Once the report is generated, click “Export to Excel” to export it in Excel format.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'asure'">
            <p>1. Access your payroll software or system and navigate to the report generation section.</p>
            <p>2. Select the option to create a new report, and choose the payroll summary report.</p>
            <p>3. Set the date range to be from 2020 to 2021 only. This will ensure that the report only includes data for those two years.</p>
            <p>4. Specify that the report should only include W-2 employees. This will filter out any independent contractors or other non-employee compensation.</p>
            <p>5. Select “Gross Pay” as one of the report columns. This will ensure that the report shows the total amount paid to each employee during the specified date range.</p>
            <p>6. Choose to include the date of pay as a separate column in the report.</p>
            <p>7. Once the report is generated, export it to Excel by selecting the export option and choosing the Excel file format.</p>
          </div>
          <div class="provider" v-show="selectedProvider.provider === 'netchex'">
            <p>1. Log in to your Netchex account.</p>
            <p>2. Click on the “Reports” tab on the top menu bar.</p>
            <p>3. Select “Payroll”  from the list of report categories.</p>
            <p>4. Under the "Payroll Summary" section, select "By Employee."</p>
            <p>5. Choose the desired date range by clicking on the calendar icon and selecting the start and end dates for the report. In this case, select the dates between January 1, 2020, and December 31, 2021.</p>
            <p>6. Under the "Employee Selection" section, choose "W-2 Employees" from the drop-down menu.</p>
            <p>7. In the "Columns" section, select "Gross Pay" and "Pay Date" to be included in the report.</p>
            <p>8. Click the “Generate Report” button to create the report.</p>
            <p>9. Once the report is generated, click on the "Export" button to export the report to Excel.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import {VARS} from "@/static/consts";
const providers = ref(VARS.PAYROLL_SOFTWARE_PROVIDERS);
const selectedProvider = ref({icon: "ico-quickbooks", provider: "quickbooks", text: "Quickbooks"});

console.log(providers.value[0])

function OnSelectedProvider(pos){
  selectedProvider.value = providers.value[pos];
}

</script>

<style scoped lang="scss" src="./payroll-providers.scss"></style>