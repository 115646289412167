export const VARS = {
    PAYROLL_SOFTWARE_PROVIDERS: [
        {
            text: 'Quickbooks',
            provider: 'quickbooks',
            icon: 'ico-quickbooks'
        },
        {
            text: 'ADP',
            provider: 'adp',
            icon: 'ico-adp'
        },
        {
            text: 'PrimePay',
            provider: 'primepay',
            icon: 'ico-primepay'
        },
        {
            text: 'Rippling',
            provider: 'rippling',
            icon: 'ico-rippling'
        },
        {
            text: 'Paycom',
            provider: 'paycom',
            icon: 'ico-paycom'
        },
        {
            text: 'APS',
            provider: 'aps',
            icon: 'icon-aps'
        },
        {
            text: 'Patriot',
            provider: 'patriot',
            icon: 'ico-patriot'
        },
        {
            text: 'BambooHR - TRAXPayroll',
            provider: 'bamboohr_traxpayroll',
            icon: 'ico-bamboohr_traxpayroll'
        },
        {
            text: 'UKG',
            provider: 'ukg',
            icon: 'ico-ukg'
        },
        {
            text: 'PAYCHEX',
            provider: 'paychex',
            icon: 'ico-paychex'
        },
        {
            text: 'Gusto',
            provider: 'gusto',
            icon: 'ico-qusto'
        },
        {
            text: 'GreyHR',
            provider: 'greyhr',
            icon: 'ico-greyhr'
        },
        {
            text: 'ISOLVED',
            provider: 'isolved',
            icon: 'ico-isolved'
        },
        {
            text: 'OnPay',
            provider: 'onpay',
            icon: 'ico-onpay'
        },
        {
            text: 'JustWorks',
            provider: 'justworks',
            icon: 'ico-justworks'
        },
        {
            text: 'Paycor',
            provider: 'paycor',
            icon: 'ico-paycor'
        },
        {
            text: 'Workday',
            provider: 'workday',
            icon: 'ico-workday'
        },
        {
            text: 'SurePayroll',
            provider: 'surepayroll',
            icon: 'ico-surepayroll'
        },
        {
            text: 'Deel',
            provider: 'deel',
            icon: 'ico-deel'
        },
        {
            text: 'Ceridian',
            provider: 'ceridian',
            icon: 'ico-ceridian'
        },
        {
            text: 'Paylocity',
            provider: 'paylocity',
            icon: 'ico-paylocity'
        },
        {
            text: 'Fingercheck Payroll',
            provider: 'fingercheck',
            icon: 'ico-fingercheck'
        },
        {
            text: 'Inova Payroll',
            provider: 'inova',
            icon: 'ico-inova'
        },
        {
            text: 'Pilot',
            provider: 'pilot',
            icon: 'ico-pilot'
        },
        {
            text: 'TriNet',
            provider: 'trinet',
            icon: 'ico-trinet'
        },
        {
            text: 'Akrivia HCM',
            provider: 'akriviahcm',
            icon: 'ico-akriviahcm'
        },
        {
            text: 'Epay HCM',
            provider: 'epayhcm',
            icon: 'ico-epayhcm'
        },
        {
            text: 'Experian Employer Services',
            provider: 'experianemployerservices',
            icon: 'ico-experianemployerservices'
        },
        {
            text: 'Hourly',
            provider: 'hourly',
            icon: 'ico-hourly'
        },
        {
            text: 'DarwinBox',
            provider: 'darwinbox',
            icon: 'ico-darwinbox'
        },
        {
            text: 'Zenefits',
            provider: 'zenefits',
            icon: 'ico-zenefits'
        },
        {
            text: '247HRM',
            provider: '247hrm',
            icon: 'ico-247hrm'
        },
        {
            text: 'CAVU HCM',
            provider: 'cavuhcm',
            icon: 'ico-cavuhcm'
        },
        {
            text: 'ToastTab.com',
            provider: 'toasttab.com',
            icon: 'ico-toasttab.com'
        },
        {
            text: 'Sage HR',
            provider: 'sagehr',
            icon: 'ico-sagehr'
        },
        {
            text: 'HROne',
            provider: 'hrone',
            icon: 'ico-hrone'
        },
        {
            text: 'Asure',
            provider: 'asure',
            icon: 'ico-asure'
        },
        {
            text: 'Netchex',
            provider: 'netchex',
            icon: 'ico-netchex'
        }
    ]
}